import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, filter, first, map, take } from "rxjs";
import { Ribbon, Unit } from 'src/app/models/entities/ribbons';
import { MutlipartService } from 'src/app/services/multipart/multipart.service';

@Injectable({
  providedIn: 'root'
})
export class RibbonService {
  ribbonState = new BehaviorSubject<Ribbon[]>([]);

  constructor(private apollo: Apollo, private multipart: MutlipartService) { }

  reload() {
    const observable = this.apollo.query<{ ribbons: Ribbon[] }>({
      query: gql`
        query adminToolRibbons {
          ribbons {
            id
            name
            unit
            threshold
            possessionLimit
            attainable
            fileId
          }
        }
      `
    })
    observable.pipe(take(1)).subscribe(result => {
      this.ribbonState.next(result.data.ribbons)
    })
    return observable
  }

  getRibbon(id: number) {
    return this.ribbonState.pipe(map(x => x.find(x => x.id === id))).pipe(filter(x => !!x)).pipe(first());
  }

  mutate(ribbons: (Omit<Ribbon, 'id'> & { id?: number, file?: File })[]) {
    const query = `
        mutation adminToolRibbons({{inputs}}) {
            ribbons {
                {{fragments}}
            }
        }
    `
    
    const inputs = [];
    const fragments = [];
    const variables: {
      [key: string]: {
        attainable: boolean,
        name: string,
        possessionLimit: number,
        threshold: number,
        unit: Unit,
        fileReferenceName?: string
      }
    } = {};
    const files: { [key: string]: File } = {};
    for (let i = 0; i < ribbons.length; i++) {
      if (ribbons[i].file)
        files[`file${i}`] = ribbons[i].file!;
      inputs.push(ribbons[i].id ? updateInput(i) : createInput(i))
      fragments.push(ribbons[i].id ? updateFragment(i, ribbons[i].id!) : createFragment(i))
      variables[`input${i}`] = {
        attainable: ribbons[i].attainable,
        name: ribbons[i].name || ribbons[i].file?.name || `file${i}`,
        possessionLimit: ribbons[i].possessionLimit,
        threshold: ribbons[i].threshold,
        unit: ribbons[i].unit,
        fileReferenceName: ribbons[i].file ? `file${i}` : undefined
      }
    }

    return this.multipart.query<{ ribbons: { [key: string]: { id: number } } }>({
      query: query.replace('{{inputs}}', inputs.join(' ')).replace('{{fragments}}', fragments.join(' ')),
      variables: variables,
      operationName: 'adminToolRibbons',
      files: files
    });
  }
}

function createInput(fileIndex: number) {
  return `$input${fileIndex}: RibbonCreateInput!`
}

function updateInput(fileIndex: number) {
  return `$input${fileIndex}: RibbonEditInput!`
}

function createFragment(fileIndex: number) {
  return `create${fileIndex}: create(input: $input${fileIndex}) { id }`
}

function updateFragment(fileIndex: number, ribbonId: number) {
  return `update${fileIndex}: update(id: ${ribbonId} input: $input${fileIndex}) { id }`
}
